import React, {Fragment, useEffect, useState} from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import {Card, CardContent} from '@material-ui/core'
import Grid from "@material-ui/core/Grid";
import useGameConfig from "../hooks/useGameConfig";

function getLocaleDate(timestamp) {
    const date = new Date(timestamp)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('de-DE', options)
}

export default function News({ location }) {
  const [news, setNews] = useState(null)
    useEffect(() => {
        fetch('https://feeds.behold.so/KEAc7voKZC7Iz1vLCv0o')
            .then(data => data.json())
            .then(news => {
                setNews(news)
                console.log(news)
            });
  }, [])
  const { texts: { others } } = useGameConfig()

  if (!news) return <Layout><div>Loading...</div></Layout>

  return (
    <Layout>
      <Seo title="Neuigkeiten"/>
        <Card style={{marginBottom: '1em'}}><CardContent><h1>{others.news}</h1></CardContent></Card>
         <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {news.map((item) => {
            return (
                <Grid item md={12} key={item.id}><Card style={{marginBottom: "1em"}}>
                    <CardContent>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={6}>
                                <a href={item.permalink} target="_blank"><img style={{width: '100%', padding: '1em'}}
                                     src={item.thumbnailUrl || item.mediaUrl}
                                     loading="lazy"
                                /></a>
                            </Grid>
                            <Grid item md={6} key={item.id}>
                                <div style={{marginTop: "1em"}}>
                                    {item.caption}
                                </div>
                                <div style={{marginTop: "1em"}}><strong>{getLocaleDate(item.timestamp)}</strong></div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card></Grid>
            )
          })}
          </Grid>
    </Layout>
  )
}